<template>
	<div class="addbingo">
		<blue-title>发布需求项目</blue-title>

		<div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="需求项目" prop="title">
					<el-input style="width:400px;" v-model="ruleForm.title"></el-input>
				</el-form-item>

				<el-form-item style="width:400px;" label="城市" prop="city">
					<div class="block">
						<el-cascader style="width:400px;" v-model="ruleForm.city" :options="chinacity" :props="{ value: 'id' }"
							clearable></el-cascader>
					</div>
				</el-form-item>

				<el-form-item label="详细位置" prop="address">
					<el-input style="width:600px;" v-model="ruleForm.address"></el-input>
				</el-form-item>

				<el-form-item label="用地面积" prop="extent">
					<el-select v-model="ruleForm.extent" placeholder="请选择用地面积">
						<el-option v-for="item in acreagecate" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="具体面积" prop="extent_value">
					<el-input style="width:400px;" v-model="ruleForm.extent_value" placeholder="平方米"></el-input>
				</el-form-item>

				<el-form-item label="项目性质" prop="attribute">
					<el-select v-model="ruleForm.attribute" placeholder="请选择性质">
						<el-option v-for="item in attributecate" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="使用年限" prop="life">
					<el-select v-model="ruleForm.life" placeholder="请选择年限">
						<el-option v-for="item in yearcate" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="容积率" prop="volumn">
					<el-input style="width:400px;" v-model="ruleForm.volumn"></el-input>
				</el-form-item>

				<el-form-item label="交易方式" prop="trade">
					<el-select v-model="ruleForm.trade" placeholder="请选择交易方式">
						<el-option v-for="item in tradecate" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>

				<!-- <el-form-item label="交易报价" prop="offer">
					<el-input style="width:400px;" v-model="ruleForm.offer" placeholder="万元"></el-input>
				</el-form-item> -->

				<el-form-item label="上传照片">
					<div>
						<piccard :useCompress="true" v-model="lunboImageList" :width="80" :height="80" :show-source="false"
							:is-single="false" />
						<div style="color:#999;font-weight:bold;text-align: left;">网站轮播图片要求宽高为570px * 350px；不超过5M；
							建议使用jpg、 jpeg、 png 格式图片 </div>
						<div />
					</div>

				</el-form-item>


				<el-form-item label="项目详情">

					<div style="border: 1px solid #ccc;">
						<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
						<Editor style="height: 500px; overflow-y: hidden;" v-model="html" :defaultConfig="editorConfig" :mode="mode"
							@onCreated="onCreated" />
					</div>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" style="width:200px;margin-left:30px;" @click="submitForm('ruleForm')">提交</el-button>
				</el-form-item>
			</el-form>


		</div>
		<!-- <el-dialog title="申请成功" :visible.sync="dialogVisible" width="30%" @close="toUrl" :before-close="handleClose">
			<span>您的经纪人资料已提交， 请耐心等待后台工作人员审核</span>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog> -->
	</div>
</template>

<script>

import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

import { detail, save } from '@/api/website/regjjr.js'
import Piccard from '@/components/website/piccard.vue'
import BlueTitle from '@/components/website/BlueTitle.vue';
import qiniu from '@/components/website/qiniu.vue'

import { qnToken, uploadQiniuForEditer } from '@/api/website/qiniu'
import utils from '@/components/Upload/utils.js'

import { enumList, addBingo, listBingo, chinacity } from '@/api/website/bingo'

export default {
	components: {
		BlueTitle,
		Piccard,
		qiniu,
		Editor,
		Toolbar
	},

	name: 'PcWebsiteJjrzc',
	props: {
		// 限制上传图片的文件大小(kb)
		size: {
			type: Number,
			default: 200000
		},
	},

	data() {
		return {
			attributecate: [],
			yearcate: [],
			tradecate: [],
			acreagecate: [],
			chinacity: [],
			qiniuData: {
				prefix: '',
				token: ''
			},
			editor: null,
			html: '<p></p>',
			toolbarConfig: {},
			editorConfig: {
				placeholder: '请输入内容...',
				MENU_CONF: {
					'uploadImage': {
						// 自定义插入图片
						async customUpload(file, insertFn) {                  // JS 语法
							// res 即服务端的返回结果

							const res = await qnToken({ 'name': file.name })
							const token = res.data.token
							const upload = await uploadQiniuForEditer({ token: token }, file)


							// 从 res 中找到 url alt href ，然后插入图片
							insertFn(upload.data.url, upload.data.name, upload.data.url)
						}
					}
				}

			},
			mode: 'default',
			dialogVisible: false,
			lunboImageList: [],

			ruleForm: {
				title: '',
				address: '',
				attribute: '',
				life: '',
				trade: '',
				extent: '',
				extent_value: '',
				type: 2, //并购，需求，农地
				data: '',
				volumn: '',
				offer: '',
				city: '',
				details: '',
			},
			rules: {
				title: [
					{ required: true, message: "标题不能为空", trigger: "blur" },
				],
				address: [
					{ required: true, message: '地址不能为空', trigger: 'blur' }
				],
				attribute: [
					{ required: true, message: '不能为空', trigger: 'change' }
				],
				life: [
					{ required: true, message: '不能为空', trigger: 'change' }
				],
				trade: [
					{ required: true, message: '不能为空', trigger: 'change' }
				],

				extent: [
					{ required: true, message: '不能为空', trigger: 'change' }
				],

				extent_value: [
					{ required: true, message: '不能为空', trigger: 'change' }
				],

				volumn: [
					{ required: true, message: '不能为空', trigger: 'change' }
				],

				// offer: [
				// 	{ required: true, message: '不能为空', trigger: 'change' }
				// ],

				city: [
					{ required: true, message: '不能为空', trigger: 'change' }
				],


			},

		};
	},

	watch: {
		lunboImageList: {
			handler(val) {
				console.log(val)
				this.ruleForm.data = val.map(item => item.id).toString()
			},
			deep: true,
			immediate: true

		}

	},

	mounted() {

		detail().then(aa => {
			if (aa.data.examine !== 1) {
				this.$router.push('/cn/list/jjrzc')
			}


			enumList().then(res => {
				this.attributecate = res.data.attribute
				this.yearcate = res.data.life
				this.tradecate = res.data.trade
				this.acreagecate = res.data.extent
			})

			chinacity().then(res => {
				this.chinacity = res.data
			})
		})


	},
	beforeDestroy() {
		const editor = this.editor
		if (editor == null) return
		editor.destroy() // 组件销毁时，及时销毁编辑器
	},

	methods: {
		handleClose() {

		},


		// 获取token
		getQiniuToken: async function (obj) {
			const _this = this
			const res = await qnToken(obj)
			if (res.code) {
				_this.qiniuData.token = res.data.token
			} else {
				_this.$message({
					message: res.data.info,
					duration: 2000,
					type: 'warning'
				})
			}
		},

		onCreated(editor) {
			this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
		},

		init() {
			info().then(res => {

			})

			detail().then(res => {
				this.ruleForm = res.data
			})

		},
		submitForm(formName) {
			// this.dialogVisible = true
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveReg()
				} else {
					return false;
				}
			});
		},

		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		//保存
		saveReg() {
			const params = this.ruleForm
			params.province_id = params.city[0]
			params.city_id = params.city[1]
			params.area_id = params.city[2]
			params.details = this.html
			console.log('params', params)

			addBingo(params).then(res => {
	
				if (res.code) {
					// this.dialogVisible = true
					const str = this.$route.path.split('/').pop()
					// alert(str)
					this.$router.push('/cn/list/mine?tab=' + str)
				}
			})
		},

		//save后跳转
		toUrl() {
			this.$rotuer.push('/cn/list/addbingo')
		}

	}

};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.addbingo {
	background: #fff;
	padding-bottom: 100px;
}

.regjjr {
	width: 1200px;
	height: 798px;
	background: #FFFFFF;
}

::v-deep .el-form-item__content {
	display: flex;

}

::v-deep .el-form-item__label {
	text-align: left;
	margin-left: 30px;
}
</style>




